@mixin button {
	background-color: $color-blue-brighter;
	padding: 1em 2em;
	border-radius: 5px;
	display: inline-block;
	color: $color-white;
	text-decoration: none;
	border: 0;
	cursor: pointer;
	text-transform: uppercase;
	font-weight: 500;
}

.button {
	@include button;	
}