.navbar {
	background-color: $color-black;
	.navbar-wrapper {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		.logo {
			img {
				display: block;
				height: 45px;
				user-drag: none;
				user-select: none;
			}
		}
		.menu {
			display: flex;
			flex-direction: row;
			align-items: center;
			.link {
				margin-right: 1.25em;
				padding: 0.5em 0;
				&:last-child {
					margin-right: 0;
				}
				&.login {
					margin-left: 1.75em;
				}
				a {
					color: $color-white;
					text-decoration: none;
					border-top: 2px solid transparent;
					border-bottom: 2px solid transparent;
					&.active {
						border-bottom: 2px solid white;
					}
				}
			}
		}
		@media screen and (max-width: 764px) {
			.menu {
				display: none;
			}
		}
	}
}