.selected-items {
	background-color: $color-grey-steam;
	padding: 2em;
	border-radius: $global-border-radius;
	.items {
		.selected-item {
			display: grid;
			grid-template-columns: 50px 3fr 1fr 25px;
			grid-gap: 10px;
			border-bottom: 1px solid white;
			padding-top: 2em;
			padding-bottom: 1em;
			margin-bottom: 1em;
			.icon {
				width: 50px;
			}
			.name {

			}
			.price {
				input {
					width: 100%;
					background-color: transparent;
					border: 0;
					text-decoration: none;
					color: white;
				}
				input:focus, textarea:focus, select:focus{
					outline: none;
				}
			}
			.remove {
				cursor: pointer;
			}
		}
	}
}