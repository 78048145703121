.item {
	background-color: $color-grey-steam;
	padding: 2em;
	cursor: pointer;
	position: relative;
	border-radius: $global-border-radius;
	.icon {
		display: block;
		width: 100%;
		max-width: 150px;
		margin: 0 auto;
		margin-bottom: 1em;
	}
	.name {
		text-align: center;
		margin-bottom: 0.5em;
	}
	.exterior {
		font-size: 0.8em;
		text-align: center;
		margin-bottom: 0.5em;
	}
	:last-child {
		margin-bottom: 0;
	}
	&:after {
		display: block;
		position: absolute;
		content: " ";
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: black;
		opacity: 0;
		transition: opacity 0.2s ease-in-out;
	}
	&.selected {
		&:after {
			opacity: 0.35;
		}
	}
	.price {
		text-align: center;
		font-weight: bold;
		margin-top: 1em;
		font-size: 1.2em;
	}
	.float {
		text-align: center;
		margin-top: 1em;
		font-size: 0.8em;
	}
}