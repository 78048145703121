.user-badge {
	padding: 1em 2em;
	border-radius: 5px;
	display: flex;
	flex-direction: row;
	align-items: center;
	a {
		color: $color-white;
		text-decoration: none;
		margin-right: 1em;
	}
	.avatar {
		display: block;
		height: 30px;
		width: 30px;
		border-radius: 100%;
		
	}
}