.heading {
	width: 100%;
	float: left;
	text-align: center;
	margin-bottom: 2em;
	.wrapper {
		display: inline-block;
		h1 {
			font-size: 3em;
			&:after {
				content: " ";
				height: 4px;
				background-color: $color-blue;
				width: auto;
				display: block;
				width: 80%;
				max-width: 100px;
				margin: 0 auto;
				margin-top: 10px;
			}
		}
	}
}