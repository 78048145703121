.account {

	.layout {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 2em;
	
		.account-info {
			.row {
				display: grid;
				grid-template-columns: 1.5fr 2fr 0.5fr;
				grid-column-gap: 2em;
				padding: 1em;
				background-color: $color-grey-steam;
				margin-bottom: 1em;
				border-radius: $global-border-radius;
				&:last-child {
					margin-bottom: 0;
				}
				.label {
					font-size: 1em;
					font-weight: bold;
				}
				.value {
					font-size: 0.8em;
					overflow: hidden; 
					white-space: nowrap; 
					text-overflow: ellipsis;
				}
				.edit {
					text-align: right;
					font-size: 0.8em;
				}
			}
		}
		.invoice-details {
			border-radius: $global-border-radius;
			background-color: $color-grey-steam;
		}
	
		@media screen and (min-width: 800px) {
			grid-template-columns: 1fr 1fr;
		}
	}

}