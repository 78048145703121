.sell {
	.layout {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 2em;
		padding-top: 2em;
		padding-bottom: 2em;
		:last-child {
			grid-row: 1;
		}
	}
	@media screen and (min-width: 1200px) {
		.layout {
			:last-child {
				grid-row: unset;
			}
			grid-gap: 2em;
			grid-column-gap: 3em;
			grid-template-columns: 2fr 1fr;
		}
	}

}


.listing-attempts-modal {
	.btn-wrapper {
		padding: 1em 0;
		text-align: right;
	}
	.listing-attempts {
		border-radius: $global-border-radius;
		max-height: 50vh;
		overflow-y: auto;
		position: relative;
		.listing-item {
			display: grid;
			grid-template-columns: 1fr 1fr;
			background-color: #111111;
			border-radius: $global-border-radius;
			margin-bottom: 1em;
			padding: 1em;
			&:last-child {
				margin-bottom: 0;
			}
			.icon {
				max-width: 100px;
			}
			.item-details, .status {
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
			.item-details {
				.name {
	
				}
				.exterior {
	
				}
			}
			.status {
				grid-column: 2;
				text-align: right;
				.title {
					font-weight: bold;
				}
				.error-message {
					
				}
			}
			
			@media screen and (min-width: 600px) {
				grid-template-columns: 1fr 1fr 1fr;
				.status {
					grid-column: unset;
				}
			}
			
		}
		// &:after {
		// 	content: "";
		// 	position: absolute;
		// 	bottom: 0;
		// 	left: 0;
		// 	right: 0;
		// 	height: 60px;
		// 	background-image: linear-gradient(to bottom, transparent, #111111);
		// }
	}
}