.loading {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	.loading-content {
		.text {
			text-align: center;
			margin-top: 2em;
			font-size: 1.25em;
		}
	}
}