.item-modal {
	.icon {
		width: 100%;
	}
	.layout {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 1em;
		@media screen and (min-width: 764px) {
			grid-template-columns: 1fr 1fr;
		}
	}
}