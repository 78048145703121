.modal {

	display: none;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 2;
	padding: 2em;

	&.open {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		background-color: rgba(0, 0, 0, 0.8);
		pointer-events: unset;
	}

	.modal-body {
		padding: 2em;
		background-color: $color-grey-steam;
		display: block;
		max-width: 800px;
		flex-basis: 100%;
		margin: 0 auto;
		position: relative;
		border-radius: $global-border-radius;
		.content {

		}
		.header {
			position: absolute;
			text-align: right;
			top: 2em;
			right: 2em;
			.close {
				cursor: pointer;
			}
		}
	}

}