.listed-items {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 1em;
	@media screen and (min-width: 600px) {
		grid-template-columns: 1fr 1fr;
	}
	@media screen and (min-width: 900px) {
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 2em;
	}
	@media screen and (min-width: 1200px) {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
}