.page-content {
	clear: both;
	max-width: 1500px;
	margin: 0 auto;
	padding: 2em 1em;
	@media screen and (min-width: 600px) {
		padding: 2em 2.5em;
	}
	@media screen and (min-width: 1600px) {
		padding: 2em 0;
	}
}