.not-found {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	.title {
		font-size: 3em;
		font-weight: bold;
		margin-bottom: 0.25em;
	}
	.go-back {

	}
}