.hero {
	min-height: 35vh;
	position: relative;
	width: 100%;
	float: left;
	overflow: hidden;
	display: flex;
	flex-direction: row;
	align-items: center;
	.background-image {
		z-index: 1;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		object-fit: cover;
		width: 100%;
    height: 100%;
		-webkit-user-drag: none;
		-khtml-user-drag: none;
		-moz-user-drag: none;
		-o-user-drag: none;
		user-drag: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-o-user-select: none;
		user-select: none;
	}
	.hero-content {
		flex-grow: 1;
		z-index: 2;
		.wrapper {
			max-width: 60ch;
			margin-left: 0;
		}
		.title {
			font-size: 2.5em;
			font-weight: bold;
		}
		.steam-login-button {
			margin-top: 2em;
		}
	}
}