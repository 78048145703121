.checkout {
	.cart {
		width: 100%;
		max-width: 800px;
		margin: 0 auto;

		.checkout-item {
			border-radius: $global-border-radius;
			background-color: $color-grey-steam;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			padding: 1em;
			.icon {
				img {
					max-width: 150px;
				}
			}
		}
	}
}