@import 'global/colors';
@import 'global/typography';
@import 'global/variables';
@import 'objects/button';
@import 'objects/navbar';
@import 'objects/page-content';
@import 'objects/footer';
@import 'objects/hero';
@import 'objects/steam-login-button';
@import 'objects/not-found';
@import 'objects/loader';
@import 'objects/loading';
@import 'objects/user-badge';
@import 'objects/inventory';
@import 'objects/selected-items';
@import 'objects/sell';
@import 'objects/heading';
@import 'objects/listed-items';
@import 'objects/item';
@import 'objects/account';
@import 'objects/modal';
@import 'objects/item-modal';
@import 'objects/notification';
@import 'objects/checkout';

body {
	background-color: $color-black;
}

* {
	&, &::after, &::before {
		box-sizing: border-box;
	}
}
